import React, { useState } from 'react'
import { PrimaryButton } from '@posten/hedwig-react'
import ParcelBoxResult from './ParcelBoxResult.jsx'

import './find-parcel-box.scss'

export const FindParcelBox = ({
    title,
    label,
    buttonText,
    mapLabel,
    noResultMessage
}) => {
    const [postCode, setPostCode] = useState('')
    const [res, setRes] = useState()

    const handleChange = (postCode) => {
        setPostCode(postCode)
    }

    function handleKeyDown(e) {
        if (e.keyCode === 13) {
            //Enter key
            lookupParcelBox()
        }
    }

    function lookupParcelBox() {
        fetch(
            window.location.origin +
                window.location.pathname +
                '/_/service/no.posten.website/pakkebox-search?postalCode=' +
                postCode +
                '&count=5'
        )
            .then((results) => {
                return results.json()
            })
            .then(
                (data) => {
                    if (data.units.length > 0) {
                        setRes(data.units)
                    } else if (data.units.length == 0) {
                        setRes([])
                    }
                },
                (error) => {}
            )
    }

    return (
        <div className='find-parcel-box hw-block hw-block--mt-large-2 hw-block--mb-large-2 hw-block--px-responsive hw-block--py-responsive'>
            <div className='hw-block hw-block--mb-medium-2-responsive'>
                <h3 className='hw-h3-title'>{title}</h3>
            </div>
            <div className='find-parcel-box-form'>
                <div>
                    <label className='hw-label'>
                        {label}
                        <input
                            className='hw-input hw-input--white'
                            type='text'
                            maxLength={4}
                            onChange={(postCode) =>
                                handleChange(postCode.target.value)
                            }
                            onKeyDown={(key) => handleKeyDown(key)}
                            value={postCode}
                        />
                    </label>
                </div>
                <div>
                    <PrimaryButton
                        size='large'
                        onClick={() => lookupParcelBox()}
                        aria-controls='searchResults'
                    >
                        {buttonText}
                    </PrimaryButton>
                </div>
            </div>
            <div id='searchResults' aria-live='polite'>
                {res && (
                    <ParcelBoxResult
                        findings={res}
                        mapLabel={mapLabel}
                        message={noResultMessage}
                    />
                )}
            </div>
        </div>
    )
}
