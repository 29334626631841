import React from 'react'

const ParcelBoxResult = ({ findings, message, mapLabel }) => {
    const items = findings.map((item, index) => (
        <li key={index}>
            <div className='hw-text-title'>{item.name}</div>
            <div>{item.location}</div>
            <div>
                {item.address}, {item.postCode} {item.city}
            </div>
            <div>
                <a className='hw-link' href={item.mapUrl} target='_blank'>
                    {mapLabel}
                </a>
            </div>
        </li>
    ))

    if (items.length > 0) {
        return (
            <div className='hw-block hw-block--mt-medium-2-responsive hw-block--mb-medium-2-responsive'>
                <ul className='hw-list hw-list--no-bullets parcel-box-result'>
                    {items}
                </ul>
            </div>
        )
    } else {
        return (
            <div className='hw-block hw-block--mt-medium-2-responsive hw-block--mb-medium-2-responsive'>
                {message}
            </div>
        )
    }
}

export default ParcelBoxResult
